/* General Card Styling */
.f-program-card {
  display: block;
  width: 100%;
  max-width: 424px;
  height: 424px;
  border: 1.5px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: #12163c;
  transition: transform 0.3s ease, border-color 0.3s ease;
  position: relative; /* Allow absolute positioning inside the card */
}

.f-card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis */
  margin-bottom: 10px; /* Space below description */
  font-family: graphik;
}

.f-program-card:hover {
  transform: scale(1.02); /* Slight scaling effect on hover */
  border-color: solid black;
}

/* Layout of the content inside the card */
.f-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}

/* Image Styling */
.f-card-image {
  width: 100%;
  height: 182px;
  border-radius: 8px;
  object-fit: fill; /* Change to cover for better image aspect ratio */
}

/* Title Styling */
.f-f-card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: auto;
  line-height: 1.2;
  color: black;
}

/* Footer Section */
.f-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  font-size: 14px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  visibility: visible; /* Ensure visibility */
  opacity: 1; /* Make it fully visible */
  transition: opacity 0.3s ease;
}

/* Author Name Styling */
.f-author-name {
  font-size: 14px;
  font-weight: bold;
  color: #808080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Ensure ellipsis for overflow */
}

/* Read More Section */
.f-read-more {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #808080;
  transition: color 0.3s ease;
}

.f-read-more:hover {
  color: black; /* Change color on hover */
}

/* Arrow Styling */
.f-arrow {
  font-size: 16px;
}

/* Horizontal Card Adjustments */
.f-card-horizontal {
  grid-column: span 1; /* Spans one column */
  grid-row: span 1; /* Spans one row */
  height: 152px; /* Horizontal card should have a smaller height */
  max-width: 100%; /* Full width */
}

.f-card-horizontal .f-card-image {
  height: 120px; /* Smaller image height for horizontal card */
}

.f-card-horizontal .f-card-description {
  display: none; /* Hide description for horizontal card */
}

.f-card-horizontal .f-card-title {
  padding-top: 14px;
  font-size: 22px; /* Smaller font size for horizontal card */
}

.f-card-horizontal .f-card-footer {
  opacity: 0; /* Hide footer by default */
}

/* Vertical Card Adjustments */
.f-card-vertical {
  grid-column: span 1; /* Spans one column */
  grid-row: span 2; /* Spans two rows */
  height: 334px; /* Full height */
  max-width: 100%;
}

.f-card-vertical p {
  font-size: 12px; /* Adjust padding for vertical card */
}

.f-card-vertical .f-card-title {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 22px; /* Larger font size for vertical card */
}

.f-card-vertical .f-card-image {
  height: 140px; /* Larger image for vertical card */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .f-program-card {
    flex: 1 1 100%; /* Stack cards vertically */
    margin: 0px 0; /* Add margin for spacing */
    min-width: 3px; /* Remove max-width for better flexibility */
    height: auto; /* Set height to auto for flexibility */
  }

  .f-card-horizontal {
    height: auto; /* Allow height to adjust based on content */
  }

  .f-card-vertical {
    height: auto; /* Allow height to adjust based on content */
  }
  
  .f-card-description {
    margin-bottom: 70px; /* Hide description for vertical card */
  }

  .f-card-image {
    margin-bottom: auto; /* Larger image for vertical card */
  }
  
  .f-card-horizontal .f-card-image {
    height: 200px; /* Maintain image height for horizontal card */
  }
  
  .f-card-title {
    font-size: 11px; /* Adjust font size for smaller screens */
  }
  
  .f-card-horizontal .f-card-footer {
    opacity: 0; /* Maintain hidden footer for horizontal card */
  }
}

@media (min-width: 1200px) and (max-width: 1325px) {
  .f-card-horizontal .f-card-title {
    font-size: 17px; /* Adjust font size for larger screens */
  }

  .f-card-vertical .f-card-title {
    font-size: 17px; /* Adjust font size for larger screens */
  }
}

@media (min-width: 1325px) and (max-width: 1380px) {
  .f-card-horizontal .f-card-title {
    font-size: 18px; /* Adjust font size for larger screens */
  }

  .f-card-vertical .f-card-title {
    font-size: 18px; /* Adjust font size for larger screens */
  }
}

@media (min-width: 1380px) and (max-width: 1410px) {
  .f-card-horizontal .f-card-title {
    font-size: 19px; /* Adjust font size for larger screens */
  }

  .f-card-vertical .f-card-title {
    font-size: 19px; /* Adjust font size for larger screens */
  }
}

@media (min-width: 1410px) and (max-width: 1460px) {
  .f-card-horizontal .f-card-title {
    font-size: 19px; /* Adjust font size for larger screens */
  }

  .f-card-vertical .f-card-title {
    font-size: 19px; /* Adjust font size for larger screens */
  }
}

@media (min-width: 1460px) and (max-width: 1500px) {
  .f-card-horizontal .f-card-title {
    font-size: 20px; /* Adjust font size for larger screens */
  }

  .f-card-vertical .f-card-title {
    font-size: 20px; /* Adjust font size for larger screens */
  }
}

@media (min-width: 769px) {
  .f-card-title {
    font-size: 22px; /* Adjust font size for larger screens */
  }

  .f-program-card {
    flex: 1 1 45%; /* Adjust cards to take 45% of the row in larger screens */
    margin: 10px; /* Add margin for spacing */
  }
}
