.popup {
  display: none; /* Hidden by default */
  position: fixed;
  right: 0;
  border-radius: 8px;
  top: 0;
  width: 38%; /* Covers 30% of the width */
  height: 100%; /* Full height */
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: hidden; /* Enable vertical scroll if needed */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
  scrollbar-width: none;
}
.overlay {
  display: none; /* Hidden by default */
  position: fixed;
  left: 0; /* Aligns to the left */
  top: 0;
  width: 70%; /* Covers 70% of the width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  overflow-y: hidden;
  z-index: 999; /* Behind the popup */
}

.overlay.show {
  overflow: hidden;
  display: block; /* Show the overlay when active */
}

.popup.show {
  display: block; /* Show the popup when active */
}

.popup-content {
  padding: 20px;
  background: white;
  border-radius: 8px;
 /* Adjust width as needed */
  position: relative;  /* Add padding for content */
}













.border-line {
  border-top: 2px solid black; /* Border line style */
  margin: 10px 0; /* Margin for spacing */
}


.pane-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
  background: white;
  border-bottom: 1px solid var(--divider-color);
  padding: 0.5rem 0.75rem;
  min-height: 3rem;
  /* Set border radius as needed */
  text-align: left; /* Align heading to the left */
  transition: none;
}

.close-popup-button {
  background: grey;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.location-map {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #EBF4FB; /* Background color for the map */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #007BFF; /* Optional: Border to simulate a map frame */
}

.map-pin {
  width: 30px;
  height: 30px;
  background-color: red; /* Color for the map pin */
  border-radius: 50%;
  position: absolute;
  bottom: 20px; /* Adjust as needed */
  left: 50%; /* Center it */
  transform: translateX(-50%); /* Center it */
}

.popup-image {
  width: 100%; /* Make image responsive */
  height: auto;
  border-radius: 8px; /* Maintain aspect ratio */
}

.program-meta {
  margin: 10px 0; /* Add margin for spacing */
}

.header-border {
  border: none;
  border-top: 2px solid var(--divider-color); /* Change this to desired border color */
  margin: 20px 0; /* Add margin above and below the border */
}

/* Flexbox Utility Classes */
.flex-center {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.spread {
  display: flex; /* Enable flexbox layout */
  justify-content: space-between; /* Space items evenly */
}

.gap-25 {
  gap: 0.75rem; /* Gap between flex items */
}

.full-width {
  width: 100%; /* Full width for elements */
}

.flex-1 {
  flex: 1; /* Allow item to grow and fill available space */
}

/* Event Link Styles */

.registration-form {
  border: 2px solid #000000; /* Change the color to your preference */
  border-radius: 10px; /* Optional: adds rounded corners */
  padding: 20px; /* Adds space inside the border */
  margin: 20px; /* Optional: adds space outside the form */
  background-color: #f9f9f9; /* Optional: background color */
}

.date-info {
  display: flex;
  align-items: center;
}

.date-border {
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding inside the border */
  display: flex; /* Flexbox to align items */
  align-items: center; /* Center align items vertically */
}
.header-line {
  position: sticky; /* Keeps the line fixed to the top when scrolling */
  top: 0;
  height: 2px;
  background-color: #dcdcdc;
  width: 100%;
  z-index: 1;
}
.month, .day {
  font-size: 1rem; /* Adjust size as necessary */
  margin: 0 5px; /* Margin on left and right */
}

.separator {
  width: 40px; /* Width of the horizontal line */
  height: 1px; /* Height of the line */
  background-color: #888; /* Color of the line */
  margin: 0 10px; /* Margin around the separator */
}

.location-and-dates {
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between date and location */
  align-items: center; /* Center items vertically */
}

.date-and-location {
  display: flex; /* Make date and location display in a row */
  align-items: center; /* Center items vertically */
}
.svg-border {
  border: 2px solid rgb(80, 79, 79); /* Black border around the SVG */
  border-radius: 5px; /* Rounded corners for the border */
  padding: 5px; /* Padding around the SVG */
  display: inline-block; /* Allows the border to fit tightly around the SVG */
}
.loc{

  font-weight: bold;
  display:inline;
  align-items: center;
  margin-left: 10px;
}
/* Container for the entire timeline */
body {
  background-color: #ffffff; /* Overall background color */
}

.timeline-container {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  padding: 10; 
  padding-right: 230px;/* Padding around the container */
}

.date-section {
  position: relative;
  margin: 0 10px; /* Reduced spacing between sections */
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-card {
  background-color: #2e2e2e; /* Dark background color for card */
  border-radius: 8px; /* Rounded corners */
  width: 50px; /* Width of the card */
  height: 50px; /* Height of the card */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5); /* Optional shadow for depth */
}

.month {
  background-color: #4a4a4a; /* Slightly lighter background for month */
  color: #ffffff; /* White text color */
  padding: 2px 0; /* Reduced padding for month */
  width: 100%; /* Full width */
  text-align: center; /* Center text */
  font-family:monospace;
  font-size: 8px; /* Smaller font size for month */
  border-top-left-radius: 8px; /* Rounded top-left corner */
  border-top-right-radius: 8px; /* Rounded top-right corner */
}

.day {
  color: #ffffff; /* White text color for day */
  font-size: 20px; /* Larger font size for day */
  padding: 2px 0; /* Reduced padding for day */
}

.label {
  text-align: center;
  margin-top: 5px; /* Space between label and icon */
  color: #000000; /* Black color for the label */
  font-size: 12px; /* Font size for the label */
}

.timeline-connector {
  height: 80px; /* Height of the connector */
  width: 2px; /* Width of the connector */
  background-color: #5e5d5d; /* Color of the connector */
}


.location-icon {
  display: flex; /* Flexbox for icon and text */
  align-items: center; /* Center icon and text vertically */
}

.location-icon svg {
  margin-right: 0px; /* Space between icon and location text */
}

.deadline-heading {
  margin-right: 15px; /* Adjust space between heading and border */
  font-size: 1.2rem; /* Adjust as necessary */
}

.date-border {
  border: 1px solid #ccc; /* Border color */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding inside the border */
  display: flex; /* Flexbox to align items */
  flex-direction: column; /* Column layout for date details */
}

.month, .day, .full-date {
  font-size: 1rem; /* Adjust size as necessary */
  margin: 2px 0; /* Space between date components */
}

/* Additional Styles */
.translucent {
  opacity: 0.9; /* Translucent effect */
}

.flex-center {
  display: flex;
  justify-content: left; /* Align items to the left */
}

.event-button {
  padding-left: 40px;
  display: flex;
  align-items: center;
  background-color: #f0f0f0; /* Light grey background */
  border: none;
  color: #333; /* Dark text color */
  padding: 10px 15px; /* Padding around the button */
  border-radius: 9px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-decoration: none; /* Remove underline from text */
  transition: background-color 0.3s; /* Smooth background transition */
}

.event-button:hover {
  background-color: #e0e0e0; /* Slightly darker grey on hover */
}

.map-placeholder {
  width: 100%;
  height: 200px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 10px 0;
}

.timeline {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.timeline-item {
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 5px 0;
}

.timeline-item h4 {
  margin: 0;
  text-align: left; /* Align timeline heading to the left */
}

.about-section {
  background-color: transparent; /* No background */
  padding: 20px; /* Optional padding for spacing */
  font-family: Arial, sans-serif; /* Simple, clean font */
  font-size: 14px; /* Decrease font size */
  text-align: left; /* Ensure content aligns to the left */
  margin: 0; /* Remove default margins */
}

.about-section h2 {
  font-size: 20px; /* Title size */
  margin: 0 0 10px 0; /* Ensure spacing only below */
  text-align: left; /* Align title to the left */
}

.about-section h3 {
  font-size: 16px; /* Section title size */
  margin: 5px 0; /* Consistent spacing above and below */
  text-align: left; /* Align section titles to the left */
}

.about-section p {
  margin: 0 0 10px 0; /* Ensure consistent spacing between paragraphs */
  text-align: left; /* Align paragraphs to the left */
}

/* Optional: Style for links */
.about-section a {
  color: #151515; /* Link color */
  text-decoration: none; /* Remove underline */
}

.about-section a:hover {
  text-decoration: underline; /* Underline on hover */
}



.separator {
  border: none; /* Remove default border */
  border-top: 1px solid #ccc; /* Custom border styling */
  margin: 15px 0; /* Margin for spacing */
}

.calendar-day {
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Column layout */
  align-items: center; /* Center items */
}

.calendar-month {
  font-size: 1.2rem; /* Month text size */
  margin-bottom: 5px; /* Space below month text */
  text-align: center; /* Center month text */
}

.calendar-date {
  font-size: 2rem; /* Date text size */
  font-weight: bold; /* Bold date */
  color: #007BFF; /* Color for date */
  text-align: center; /* Center date text */
}

.information-section {
  background-color: #f8f9fa; /* Light gray background for section */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding around section */
  margin: 20px 0; /* Margin around section */
}

.information-section h2 {
  font-size: 1.5rem; /* Main title size */
  margin-bottom: 10px; /* Space below the title */
  text-align: left; /* Align title to the left */
}

.information-section p {
  margin: 0; /* Remove default margin */
  text-align: left; /* Align paragraph to the left */
}

.information-section a {
  color: #007BFF; /* Link color */
}

.information-section a:hover {
  text-decoration: underline; /* Underline on hover */
}
/* Media query for smaller screens */
@media (max-width: 768px) { /* Adjust the max-width value as necessary */
  .popup {
    width: 100%; /* Full width for smaller screens */
    height: 100%; /* Full height */
    border-radius: 0; /* Remove border radius for full-screen effect */
  }
}