/* InsertProgram.css */

.insert-program-form {
  max-width: 700px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  color: #333;
}

.form-label {
  display: block;
  margin: 10px 0 5px;
  font-weight: bold;
}

.form-input,
.form-textarea {
  width: 97%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-textarea {
  resize: vertical; /* Allow vertical resizing for textareas */
  height: 80px;    /* Set a default height */
}

.i-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px; /* Add margin for separation */
}

button:hover {
  background-color: #45a049;
}

.contact-info-title,
.social-media-title,
.faqs-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.faq-item {
  margin-bottom: 15px; /* Space between FAQs */
}

.add-faq-button {
  background-color: #007BFF;
}

.add-faq-button:hover {
  background-color: #0056b3;
}

.submit-button {
  background-color: #28a745;
}

.submit-button:hover {
  background-color: #218838;
}
