/* General Card Styling */
.program-card {
  display: block;
  width: 100%;
  max-width: 424px;
  height: 424px;
  border: 1.5px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: #12163c;
  transition: transform 0.3s ease, border-color 0.3s ease;
  position: relative; /* Allow absolute positioning inside the card */
}

.card-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis */
  margin-bottom: 10px; /* Space below description */
  font-family: graphik;
}

.program-card:hover {
  transform: scale(1.02); /* Slight scaling effect on hover */
  border-color: solid black;
}

/* Layout of the content inside the card */
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
}

/* Image Styling */
.card-image {
  width: 100%;
  height: 182px;
  border-radius: 8px;
  object-fit: fill; /* Change to cover for better image aspect ratio */
}

/* Title Styling */
.card-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: auto;
  line-height: 1.2;
  color: black;
}

/* Footer Section */
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  font-size: 14px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  visibility: visible; /* Ensure visibility */
  opacity: 1; /* Make it fully visible */
  transition: opacity 0.3s ease;
}

/* Author Name Styling */
.author-name {
  font-size: 14px;
  font-weight: bold;
  color: #808080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Ensure ellipsis for overflow */
}

/* Read More Section */
.read-more {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #808080;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: black; /* Change color on hover */
}

/* Arrow Styling */
.arrow {
  font-size: 16px;
}

/* Horizontal Card Adjustments */
.card-horizontal {
  grid-column: span 1; /* Spans one column */
  grid-row: span 1; /* Spans one row */
  height: 252px; /* Horizontal card should have a smaller height */
  max-width: 100%; /* Full width */
}

.card-horizontal .card-image {
  height: 180px; /* Smaller image height for horizontal card */
}

.card-horizontal .card-description {
  display: none; /* Hide description for horizontal card */
}

.card-horizontal .card-title {
  padding-top: 14px;
  font-size: 17.5px; /* Smaller font size for horizontal card */
}

.card-horizontal .card-footer {
  opacity: 0; /* Hide footer by default */
}

/* Vertical Card Adjustments */
.card-vertical {
  grid-column: span 1; /* Spans one column */
  grid-row: span 2; /* Spans two rows */
  height: 434px; /* Full height */
  max-width: 100%;
}

.card-vertical p {
  font-size: 12px; /* Adjust padding for vertical card */
}

.card-vertical .card-title {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 22px; /* Larger font size for vertical card */
}

.card-vertical .card-image {
  height: 200px; /* Larger image for vertical card */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .program-card {
    flex: 1 1 100%; /* Stack cards vertically */
    margin: 0px 0; /* Add margin for spacing */
    min-width: 3px; /* Remove max-width for better flexibility */
    height: auto; /* Set height to auto for flexibility */
  }
  
  .card-horizontal {
    height: auto; /* Allow height to adjust based on content */
  }

  .card-vertical {
    height: auto; /* Allow height to adjust based on content */
  }
  .card-description {
    margin-bottom: 70px; /* Hide description for vertical card */
  }
.card-image {
    margin-bottom: auto; /* Larger image for vertical card */
  }
  .card-horizontal .card-image {
    height: 200px; /* Maintain image height for horizontal card */
  }
.card-title {
    font-size: 11px; /* Adjust font size for smaller screens */
  }
  .card-horizontal .card-footer {
    opacity: 0; /* Maintain hidden footer for horizontal card */
  }
}
@media (min-width: 1200px) and (max-width: 1325px) {
  
 .card-horizontal .card-title{
  font-size: 17px; /* Adjust font size for larger screens */
  }
  .card-vertical .card-title{
  font-size: 17px; /* Adjust font size for larger screens */
  }
 }
  
 @media (min-width: 1325px) and (max-width: 1380px) {
  
  .card-horizontal .card-title{
   font-size: 18px; /* Adjust font size for larger screens */
   }
   .card-vertical .card-title{
   font-size: 18px; /* Adjust font size for larger screens */
   }
  }
  @media (min-width: 1380px) and (max-width: 1410px) {
  
    .card-horizontal .card-title{
     font-size: 19px; /* Adjust font size for larger screens */
     }
     .card-vertical .card-title{
     font-size: 19px; /* Adjust font size for larger screens */
     }
    }
    @media (min-width: 1410px) and (max-width: 1460px) {
  
      .card-horizontal .card-title{
       font-size: 19px; /* Adjust font size for larger screens */
       }
       .card-vertical .card-title{
       font-size: 19px; /* Adjust font size for larger screens */
       }
      }
      @media (min-width: 1460px) and (max-width: 1500px) {
  
        .card-horizontal .card-title{
         font-size: 20px; /* Adjust font size for larger screens */
         }
         .card-vertical .card-title{
         font-size: 20px; /* Adjust font size for larger screens */
         }
        }
@media (min-width: 769px) {
  .card-title {
    font-size: 22px; /* Adjust font size for larger screens */
  }
  .program-card {
    flex: 1 1 45%; /* Adjust cards to take 45% of the row in larger screens */
    margin: 10px; /* Add margin for spacing */
  }
}
