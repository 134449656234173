
  
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  max-width: 100%;
  /* padding: 40px 20px;  */
}

.footer-text {
  color: white; /* Sets text color to white */
}

  /* Left section */
  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
 
  .footer-waitlist {
    margin-top: 140px;
    text-align: left;
  }
  
  .footer-waitlist form {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .footer-waitlist {
    position: relative; /* Position relative for absolute children */
}

.footer-waitlist input {
    padding: 10px 0; /* Add top and bottom padding */
    border: none; /* Remove default border */
    background-color: transparent; /* Make background transparent */
    color: #c3c8ff; /* Light text color */
    margin-bottom: 10px;
    font-size: 16px;
    width: 100%; /* Optional: Makes input take full width of parent */
    outline: none; /* Remove outline on focus */
    border-bottom: 1px solid #a5bcfd; /* Create underline effect */
}

/* Placeholder styles */
.footer-waitlist input::placeholder {
    color: #a5bcfd; /* Placeholder color */
    opacity: 0.7; /* Adjusts the opacity for placeholder text */
}

/* Optionally, if you want an icon in the input */
.footer-waitlist .input-container {
    position: relative;
}

.footer-waitlist .input-icon {
    position: absolute;
    left: 10px; /* Adjust as needed */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center adjustment */
    color: #c3c8ff; /* Color of the icon */
}

.footer-waitlist input {
    padding-left: 30px; /* Add padding to the left for the icon */
}

  
  /* .footer-waitlist button {
    background-color: #ffffff;
    color: #12163f;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
  } */
  @media (min-width: 768px) {
    .footer-links {
        margin-top: 340px;
    }
}

@media (max-width: 767px) {
    .footer-links {
        margin-top: 10px;
    }
}
